import React, {useContext} from 'react';
//import images
import EmmaImg from '../img/about/Emma.jpg';
import EmmaCircle from '../img/about/EmmaCircle.png';

//import link
import { Link } from 'react-router-dom';

//import motion
import { motion } from 'framer-motion';
// import transition
import {transition1} from '../transitions'
import { CursorContext } from '../context/CursorContext';

const About = () => {
  const {mouseEnterHandler, mouseLeaveHandler} = useContext(CursorContext);
  return <motion.section 
    initial={{ opacity:0, y: '100%' }} 
    animate={{ opacity:1, y:0 }} 
    exit={{ opacity:0, y:'100%' }}
    transition={transition1}
    className='section overflow-scroll'>
    <div className='container mx-auto relative pt-[200px] md:pt-[140px] h-full pb-15'>
      {/*text & img wrapper */}
      <div className='flex flex-col md:flex-row  h-full items-center md:items-start
      justify-center gap-x-16 gap-y-8 text-center pb-100'>
        <div className='hidden md:flex bg-blue-200 max-w-md lg:max-h-full lg:order-none'>
          <img className='object-cover h-full w-full'
          src={EmmaImg} alt='' />
        </div>
        <div className='flex md:hidden max-w-sm h-[40%] justify-center'>
          <img className=""
          src={EmmaCircle} alt='' />
        </div>
        {/*text*/}
        <motion.div 
          initial={{ opacity:0, y: '-80%' }} 
          animate={{ opacity:1, y:0 }} 
          exit={{ opacity:0, y:'0%' }}
          transition={transition1}
          onMouseEnter={mouseEnterHandler}
          onMouseLeave={mouseLeaveHandler}
          className='lg:w-auto z-10 flex flex-col
          justify-center items-center md:items-start md:text-left'>
          <h1 className='h1'>
            Welcome! <br /> Bienvenue! 
          </h1>
          <p className='mb-6 w-full pl-5 pr-5 md:pl-0 md:pr-0 md:max-w-md'>
           My name is Emma Tysinger, and I'm a photographer based in Paris, France. I'm an avid skier, explorer, and photographer—with a love for discovering new places and capturing them through my lens.
          <br />
          <br />
          I first started 
          photography in high school when I would take photos of my backyard in Vermont and on family vacations. 
          Over the years, I’ve had the chance to 
          explore a variety of genres and shoot film. I currently love to shoot 
          travel, architectural and urban photography! I've also expanded to taking graduation photos, portraits and group photoshoots.
          Feel free to reach out if you’d like to book a shoot or connect!
          </p>
          <br />
          <Link to={'/'} className='btn'>
            View my work</Link>
        </motion.div>
        
        
      </div>
    </div>
  </motion.section>;
};

export default About;
